import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'
import './nav.css'


function Nav() {
    return (
        <div className="full">
            <div className="navgroup">
                <div className="navleft">
                    <Link to="/">
                        <img width="60" height="40" src={require('../../content/assets/initials.png')} />
                    </Link>
                </div>
                <div className="navright">
                    <a href="/courses">
                        <h1>Courses</h1>
                    </a>
                    <a href="/mentorship">
                        <h1>Mentorship</h1>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Nav