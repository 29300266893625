import React from 'react'
import { Link } from 'gatsby'
import Nav from "../components/nav"
import Container from "../components/container"
import Footer from "../components/footer"
import "./master.css"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    return (
      <div>
        <Nav />
        <Container>
          {header}
          {children}
        </Container>
        <Footer />
      </div>
    )
  }
}

export default Layout
