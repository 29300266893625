import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from "styled-components"

import Layout from '../components/Layout'
import SEO from '../components/seo'

const Course = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 2rem 2rem 2rem 2rem;
  margin-bottom: 1rem;
  line-height: 1.4;
  background-color: #eee;
  font-size: 1.6rem;
  color: 333;
`

const Grid = styled.div`

`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
`

const List = styled.ul`
  margin: 1rem 0rem 2rem 0rem;
  font-size: 1.4rem;
`

const Anchor = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: #0D5C00;
`

const End = styled.div`
  align-self: flex-end;
  width: 100%;
`


const Intro = styled.div`
    color: #333;
    font-size: 1.8rem;
    grid-column: 1 / 3;
    line-height: 1.3;
    margin-bottom: 4rem;
    padding-right: 4rem;

    @media only screen and (max-width: 768px) {
      grid-column: 1 / 1;
      margin-bottom: 3rem;
    }
`

const Headline = styled.h1`
    font-size: 5rem;
    margin-bottom: 1rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
      margin-top: 1rem;
      font-size: 3rem;
      line-height: 1.3;
    }
`
class LegacyButton extends React.Component {
  componentDidMount() {
      this.stripe = Stripe('pk_live_W0xMBmT494GQkvMJ46wpCGK500aNqln1rZ');
  }
  render() {
      return(
          <form onSubmit={event => {
              event.preventDefault();
              this.stripe.redirectToCheckout({
                  items: [{sku: 'sku_GdfsOvlTDaiNdj', quantity: 1}],
                  // Do not rely on the redirect to the successUrl for fulfilling
                  // purchases, customers may not always reach the success_url after
                  // a successful payment.
                  // Instead use one of the strategies described in
                  // https://stripe.com/docs/payments/checkout/fulfillment
                  successUrl: window.location.protocol + '//newpragmatic.com/success',
                  cancelUrl: window.location.protocol + '//newpragmatic.com/canceled',
                })
                .then(function (result) {
                  if (result.error) {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer.
                    var displayError = document.getElementById('error-message');
                    displayError.textContent = result.error.message;
                  }
                });
              }
          }>
              <button type="submit">$500 — Buy Now</button>
          </form>
      )
  }
}

class ProductsIndex extends React.Component {
    render() {
      const { location, title, children } = this.props
      const rootPath = `${__PATH_PREFIX__}/`
      let header
  
      return (
        <Layout>
          <SEO
            title="Courses available"
            keywords={[`curriculum`, `product design`, `UX design`, `user experience`]}
          />
          <Course>
              <div>
                <Title>Legacy Mode</Title>
                <p>This self-paced offering is intended for New Pragmatic designers who enrolled during 2019 and are eligible for the 2019 individual course rate. The course fee includes:</p>
                <List>
                    <li><strong>></strong> Weekly one-hour mentor sessions with Chris Courtney for two months.</li>
                    <li><strong>></strong> Two months of daily feedback and guidance. The specific area of study should be confined to Interface, Frontend, or Career Mode.</li>
                    <li><strong>></strong> Inclusion in daily-standup activities.</li>
                    <li><strong>></strong> Access to weekly workshops.</li>
                    <li><strong>></strong> Access to private New Pragmatic community channels.</li>
                </List>
              </div>
              <LegacyButton />
            </Course>
        </Layout>
      )
    }
  }
  
  export default ProductsIndex